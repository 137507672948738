import ApiRepository from "./api/api_repository";
import { apiUrl } from "./api/config";
import loginHandler from "./auth/loginHandler";
import derivTokenHandler from "./auth/derivTokenHandler";

// Initialize the ApiRepository with the token
const apiRepository = new ApiRepository();
window.apiRepository = apiRepository; // Add instance to global window object

const publicRoutes = ["/auth-log-in.html", "/auth-sign-up.html", "/home.html"]; // Add all public routes here
function getTokenFromQueryString() {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token"); // Replace 'token' with the actual parameter name
  const userId = urlParams.get("key"); // Replace 'token' with the actual parameter name
  return { userId, token };
}
function checkLoginStatus() {
  console.log("Checking auth status");

  apiRepository
    .isLoggedIn()
    .then(async (isLoggedIn) => {
      if (!isLoggedIn) {
        console.log(window.location.pathname);

        const {userId, token} = getTokenFromQueryString();
        console.log({ userId, token });
        if (token && userId && await apiRepository.loginByToken({ userId, token })) {
          
          window.location.href = "/";
          return;
        }

        if (!publicRoutes.includes(window.location.pathname)) {
          try {
            document.getElementById("d-login-btn").style.display = "";
            document.getElementById("d-logout-btn").style.display = "none";
            document.getElementById("d-profile-btn").style.display = "none";
          } catch (error) {}
          // window.location.href = apiUrl + '/welcome'; // Redirect to the login page
        }
      } else if (
        isLoggedIn &&
        (window.location.pathname == "/auth-log-in.html" ||
          window.location.pathname == "/auth-sign-up.html")
      ) {
        window.location.href = "/";
      } else {
        console.debug("Authentication confirmed");
        try {
          document.getElementById("d-login-btn").style.display = "none";
          document.getElementById("d-logout-btn").style.display = "";
          document.getElementById("d-profile-btn").style.display = "";
        } catch (error) {}
      }
    })
    .catch((error) => {
      console.error("Error checking login status:", error);
      // if (!publicRoutes.includes(window.location.pathname)) {
      //   window.location.href = apiUrl + "/welcome"; // Redirect to the login page
      // }
    });

     document.body.classList.remove("loading");
}

checkLoginStatus();

try {
  loginHandler();
} catch (error) {
  console.error(error);
}

try {
  derivTokenHandler();
} catch (error) {
  console.error(error);
}